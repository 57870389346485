import React, { FC, PropsWithChildren } from "react";
import { Box, Flex, Heading, chakra } from "@chakra-ui/react";
import { GatsbyImage, ImageDataLike, getImage, getSrc } from "gatsby-plugin-image";

import { ChakraMDXProvider } from "../provider";
import { ResponsiveContainer } from "../ui";
import { Footer } from "./footer";
import { Navigation } from "./navigation";
import { Seo } from "./seo";

export type LayoutProps = PropsWithChildren<{
  title?: string;
  description?: string;
  featuredImage?: ImageDataLike | null;
}>;

const Image = chakra(GatsbyImage);

export const Layout: FC<LayoutProps> = ({ title, description, featuredImage, children }) => {
  const featuredImageData = featuredImage && getImage(featuredImage);
  const ogImage = featuredImageData && getSrc(featuredImageData);
  return (
    <ChakraMDXProvider>
      <Seo title={title} description={description} ogImage={ogImage || undefined} />
      <Flex flexDirection="column" minHeight="100vh">
        <Navigation />
        <Box as="main" paddingY={[2, 6]}>
          <ResponsiveContainer marginBottom={4}>
            <Heading as="h1">{title}</Heading>
            {featuredImageData && <Image image={featuredImageData} alt="" objectFit="cover" maxHeight="200px" my={8} />}
          </ResponsiveContainer>
          <ResponsiveContainer>{children}</ResponsiveContainer>
        </Box>
        <Footer marginTop="auto" />
      </Flex>
    </ChakraMDXProvider>
  );
};
