import React, { FC } from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";

export type LinkProps<TState> = GatsbyLinkProps<TState> & ChakraLinkProps;

export const Link = React.forwardRef(function Link<TState>({ children, to, ...rest }: LinkProps<TState>, ref: React.ForwardedRef<HTMLAnchorElement>) {
  const isInternal = /^\/(?!\/)/.test(to);
  if (isInternal) {
    return (
      <ChakraLink ref={ref} asChild {...rest}>
        <GatsbyLink to={to}>{children}</GatsbyLink>
      </ChakraLink>
    );
  } else {
    return (
      <ChakraLink ref={ref} rel="noindex,nofollow" target="_blank" href={to} {...rest}>
        {children}
      </ChakraLink>
    );
  }
});
