import React from "react";
import type { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from "gatsby";
import { ChakraProvider } from "@chakra-ui/react";

import { ColorModeProvider } from "../components/ui";
import { system } from "../theme";

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) => {
  return (
    <ChakraProvider value={system}>
      <ColorModeProvider defaultTheme="dark" enableSystem={false}>
        {element}
      </ColorModeProvider>
    </ChakraProvider>
  );
};
