import React, { FC } from "react";
import { Box, Flex, Group, Icon, IconButton, List, chakra } from "@chakra-ui/react";

import { ColorModeButton, Link, ResponsiveContainer } from "../ui";

export type NavigationProps = {};

const NavLink = chakra(Link, {
  base: {
    color: "inherit",
    textDecoration: "none",
    _hover: { color: "inherit", textDecoration: "none" },
  },
});

export const Navigation: FC<NavigationProps> = () => {
  return (
    <Box borderBottom="2px solid" borderColor="primary.500" background={{ base: "gray.200", _dark: "gray.900" }}>
      <ResponsiveContainer>
        <Flex as="nav" justify="space-between" align="center" paddingY={[2, 6]}>
          <Group>
            <NavLink to="/">Lucas Loreggia</NavLink>
          </Group>
          <Group>
            <NavLink to="/projects">Projects</NavLink>
            <NavLink to="/blog">Blog</NavLink>
            <NavLink to="/content/about">About</NavLink>
            <ColorModeButton />
          </Group>
        </Flex>
      </ResponsiveContainer>
    </Box>
  );
};
