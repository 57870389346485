import React, { FC, PropsWithChildren } from "react";
import { MDXProvider } from "@mdx-js/react";
import { Heading, Image, List, Separator, Table, Text } from "@chakra-ui/react";

import { Link } from "../ui";

const Paragraph = (props: any) => <Text my={4} {...props} />;
const Heading1 = (props: any) => <Heading as="h1" {...props} />;
const Heading2 = (props: any) => <Heading as="h2" {...props} />;
const Heading3 = (props: any) => <Heading as="h3" {...props} />;
const Heading4 = (props: any) => <Heading as="h4" {...props} />;
const Heading5 = (props: any) => <Heading as="h5" {...props} />;
const Heading6 = (props: any) => <Heading as="h6" {...props} />;
const ThematicBreak = (props: any) => <Separator my={8} {...props} />;
const Blockquote = (props: any) => <Text as="blockquote" my={4} fontStyle="italic" {...props} />;
const UList = (props: any) => <List.Root as="ul" listStyleType="disc" my={4} {...props} />;
const OList = (props: any) => <List.Root as="ol" listStyleType="number" my={4} {...props} />;
const MyListItem = (props: any) => <List.Item ml={8} {...props} />;
const MyTable = (props: any) => <Table.Root my={4} {...props} />;
const TableRow = (props: any) => <Table.Row {...props} />;
const TableHeading = (props: any) => <Table.ColumnHeader {...props} />;
const TableCell = (props: any) => <Table.Cell {...props} />;
const Emphasis = (props: any) => <Text as="em" fontStyle="italic" {...props} />;
const Strong = (props: any) => <Text as="strong" fontWeight="bold" {...props} />;
const Delete = (props: any) => <Text textDecoration="line-through" {...props} />;
const Break = (props: any) => <Separator my={4} {...props} />;
const MyLink = (props: any) => <Link colorScheme="primary" {...props} />;
const MyImage = (props: any) => <Image {...props} my={4} />;

const components = {
  p: Paragraph,
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
  thematicBreak: ThematicBreak,
  blockquote: Blockquote,
  ul: UList,
  ol: OList,
  li: MyListItem,
  table: MyTable,
  tr: TableRow,
  th: TableHeading,
  td: TableCell,
  em: Emphasis,
  strong: Strong,
  delete: Delete,
  hr: Break,
  a: MyLink,
  img: MyImage,
};

export const ChakraMDXProvider: FC<PropsWithChildren> = ({ children }) => {
  return <MDXProvider components={components}>{children}</MDXProvider>;
};
