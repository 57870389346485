import { createSystem, defaultConfig } from "@chakra-ui/react";

export const system = createSystem(defaultConfig, {
  theme: {
    tokens: {
      breakpoints: {
        sm: { value: "30em" },
        md: { value: "48em" },
        lg: { value: "62em" },
        xl: { value: "80em" },
        "2xl": { value: "96em" },
      },
      colors: {
        primary: {
          50: { value: "#fafcf5" },
          100: { value: "#f5faeb" },
          200: { value: "#e4f2ce" },
          300: { value: "#d1ebb2" },
          400: { value: "#a9de7e" },
          500: { value: "#7cce50" },
          600: { value: "#69ba41" },
          700: { value: "#4e9c2d" },
          800: { value: "#387d1d" },
          900: { value: "#235c11" },
          950: { value: "#133b06" },
        },
      },
      fonts: {
        heading: { value: "'Saira Variable'" },
        body: { value: "'Saira Variable'" },
      },
      sizes: {
        container: {
          sm: { value: "full" },
          md: { value: "30em" },
          lg: { value: "48em" },
          xl: { value: "62em" },
          "2xl": { value: "80em" },
        },
      },
    },
    semanticTokens: {
      colors: {
        primary: {
          solid: { value: "{colors.primary.500}" },
          contrast: { value: "{colors.primary.100}" },
          fg: { value: "{colors.primary.700}" },
          muted: { value: "{colors.primary.100}" },
          subtle: { value: "{colors.primary.200}" },
          emphasized: { value: "{colors.primary.300}" },
          focusRing: { value: "{colors.primary.500}" },
        },
      },
    },
    recipes: {
      heading: {
        base: {},
        variants: {
          as: {
            h1: { fontSize: "4xl", lineHeight: 1.1 },
            h2: { fontSize: "3xl", lineHeight: 1.3 },
            h3: { fontSize: "2xl", lineHeight: 1.5 },
            h4: { fontSize: "xl", lineHeight: 1.7 },
            h5: { fontSize: "lg", lineHeight: 1.8 },
            h6: { fontSize: "md", lineHeight: 1.9 },
            h7: { fontSize: "sm", lineHeight: 2 },
            h8: { fontSize: "xs", lineHeight: 2 },
          },
        },
      },
      link: {
        base: {
          colorPalette: "primary",
        },
      },
    },
  },
});
