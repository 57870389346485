import React, { FC } from "react";
import { Card, CardRootProps, LinkBox, LinkOverlay, chakra } from "@chakra-ui/react";
import { GatsbyImage, IGatsbyImageData, ImageDataLike, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

export type LinkCardProps = Omit<CardRootProps, "title"> & {
  to: string;
  title?: React.ReactNode | null;
  footer?: React.ReactNode | null;
  image?: ImageDataLike | null;
};

const Image = chakra(GatsbyImage);

export const LinkCard: FC<LinkCardProps> = ({ to, title, image, footer, children, ...rest }) => {
  const imageData = image && getImage(image);
  return (
    <LinkBox asChild>
      <Card.Root
        transition="all 0.5s"
        background={{ base: "gray.50", _dark: "gray.700" }}
        borderWidth={0}
        _hover={{ background: { base: "gray.100", _dark: "gray.600" } }}
        {...rest}
      >
        {imageData && <Image image={imageData} alt="" objectFit="cover" width="full" height="150px" borderTopRadius="var(--chakra-radii-md)" />}
        {(title || imageData) && (
          <Card.Header fontSize="large">
            <LinkOverlay asChild>
              <Link to={to} _hover={{ textDecoration: "none" }}>
                {title}
              </Link>
            </LinkOverlay>
          </Card.Header>
        )}
        <Card.Body>{children}</Card.Body>
        {footer && <Card.Footer>{footer}</Card.Footer>}
      </Card.Root>
    </LinkBox>
  );
};
